<template>
  <div class="product">
    <header-v />

    <div class="product_wrapper">
      <div class="product_banner">
        <img src="@/assets/imgs/about_bg.jpg" alt="" />
      </div>
      <div class="body__content_box">
        <a-row :gutter="[64, 96]">
          <a-col :span="8">
            <div class="product_item">
              <img src="@/assets/imgs/special2.png" alt="" />
              <div
                class="product_text text-white d-flex align-items-center justify-content-center"
              >
                <div class="d-inline-block">
                  <h3 class="text-white">党政人才培训</h3>
                  <!-- <p>线下 + 线上 —— 双模式</p>
                  <p>走出去 + 请进来 —— 双核心</p> -->
                </div>
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="product_item">
              <img src="@/assets/imgs/item_01.jpg" alt="" />
              <div
                class="product_text text-white d-flex align-items-center justify-content-center"
              >
                <div class="d-inline-block">
                  <h3 class="text-white">廉洁作风</h3>
                  <!-- <p>线下 + 线上 —— 双模式</p>
                  <p>走出去 + 请进来 —— 双核心</p> -->
                </div>
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="product_item">
              <img src="@/assets/imgs/theme_bg2.jpg" alt="" />
              <div
                class="product_text text-white d-flex align-items-center justify-content-center"
              >
                <div class="d-inline-block">
                  <h3 class="text-white">忆苦思甜</h3>
                  <!-- <p>线下 + 线上 —— 双模式</p>
                  <p>走出去 + 请进来 —— 双核心</p> -->
                </div>
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="product_item">
              <img src="@/assets/imgs/special1.jpg" alt="" />              
              <div
                class="product_text text-white d-flex align-items-center justify-content-center"
              >
                <div class="d-inline-block">
                  <h3 class="text-white">企业人才培训</h3>
                  <!-- <p>线下 + 线上 —— 双模式</p>
                  <p>走出去 + 请进来 —— 双核心</p> -->
                </div>
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="product_item">
              <img src="@/assets/imgs/px01.jpg" alt="" />              
              <div
                class="product_text text-white d-flex align-items-center justify-content-center"
              >
                <div class="d-inline-block">
                  <h3 class="text-white">职业技能培训</h3>
                  <!-- <p>线下 + 线上 —— 双模式</p>
                  <p>走出去 + 请进来 —— 双核心</p> -->
                </div>
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="product_item">
              <img src="@/assets/imgs/theme_bg4.jpg" alt="" />
              
              <div
                class="product_text text-white d-flex align-items-center justify-content-center"
              >
                <div class="d-inline-block">
                  <h3 class="text-white">主题教育</h3>
                  <!-- <p>线下 + 线上 —— 双模式</p>
                  <p>走出去 + 请进来 —— 双核心</p> -->
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>

      <div class="product_content">
          <div class="body__content_box">
              <h3 class="pro_title text-center">服务优势</h3>
              <div class="pro_list_box">
                  <a-row :gutter="[64, 96]">
                    <a-col :span="6">
                        <div class="pro_service_box d-flex flex-column align-items-center">
                            <div class="service_item border-radius-half bg-white d-flex align-items-center justify-content-center">
                                <img src="@/assets/imgs/gyqy.png" alt="">
                            </div>
                            <p class="text-center text-lg font-weight-bold">国企基因</p>
                        </div>
                    </a-col>
                    <a-col :span="6">
                        <div class="pro_service_box d-flex flex-column align-items-center">
                            <div class="service_item border-radius-half bg-white d-flex align-items-center justify-content-center">
                                <img src="@/assets/imgs/sddz.png" alt="">
                            </div>
                            <p class="text-center text-lg font-weight-bold">深度定制</p>
                        </div>
                    </a-col>
                    <a-col :span="6">
                        <div class="pro_service_box d-flex flex-column align-items-center">
                            <div class="service_item border-radius-half bg-white d-flex align-items-center justify-content-center">
                                <img src="@/assets/imgs/pxjd.png" alt="">
                            </div>
                            <p class="text-center text-lg font-weight-bold">培训基地</p>
                        </div>
                    </a-col>
                    <a-col :span="6">
                        <div class="pro_service_box d-flex flex-column align-items-center">
                            <div class="service_item border-radius-half bg-white d-flex align-items-center justify-content-center">
                                <img src="@/assets/imgs/fw.png" alt="">
                            </div>
                            <p class="text-center text-lg font-weight-bold">特色服务</p>
                        </div>
                    </a-col>
                  </a-row>
              </div>
          </div>
      </div>
    </div>
    <footer-c />
  </div>
</template>
<script>
import HeaderV from '@/components/Header'
import FooterC from '../../components/FooterC'

export default {
  name: 'Product',
  components: {
    HeaderV,
    FooterC,
  },
}
</script>

<style lang="less" scoped>
.product_wrapper {
  .product_banner {
    // height: 560px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 100px;
    img {
      width: 100%;
    }
  }
  .product_item {
    width: 100%;
    height: 220px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      max-height: 100%;
    }
    .product_text {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 220px;
      font-size: 24px;
      background: rgba(0, 0, 0, 0.5);
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;

      p {
        font-size: 16px;
      }
    }

    &:hover .product_text {
      opacity: 1;
    }
  }

  .product_content {
      padding-top: 40px;
      padding-bottom: 80px;
      margin-top: 80px;
      background-color: #f5f5f5;

      .pro_title {
          font-size: 28px;
          margin-bottom: 40px;
      }

    .pro_list_box {
        .pro_service_box {
            .service_item {
                width: 120px;
                height: 120px;
                margin-bottom: 12px;
                img {
                    width: 80px;
                    transition: all 0.3s linear;
                    -webkit-transition: all 0.3s linear;
                }

                &:hover img {
                    width: 100px;
                }
            }
        }
    }
  }
}
</style>